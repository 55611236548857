import './App.css';

function MenuButton() {
    return (
        <div>
            <a href="/" >
                <button className="menu_button">&lt; Hovedside</button>
            </a>
        </div >
    )
}

export default MenuButton;